import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  html {
    z-index: 0;
    scroll-behavior: smooth;
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
